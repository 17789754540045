import React, { Component } from 'react';
import { Chart } from '@progress/kendo-charts-react-wrapper';
import { fetchData } from './../../requests/api_calls';
import { Modal } from 'react-bootstrap';
import { DatePicker } from '@progress/kendo-dateinputs-react-wrapper';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import moment from 'moment';
import * as helper from './../../scripts/helper';
import { DataLoadingSpinner } from './../../components/spinners';
import values from "object.values";
import format from 'string-format';

const defaultDaysAgo = 7;
const custom = 'custom';
const toDate = 'toDate';
const fromDate = 'fromDate';
const type = 'type';
const status = 'status';
const view = 'view';
const calendarFormat = 'M-D-YYYY';
const yearMonthDay = 'YYYY-MM-DD';
const loadTime = 500;

// Graph colors
const internal_color = "rgb(68, 87, 111)";
const inbound_color = "rgb(195, 213, 0)";
const outlocal_color = "rgb(255, 158, 21)";
const outLD_color = "rgb(230, 85, 178)";
const international_color = "rgb(255, 83, 70)";
const other_color = 'purple';

const setFromDate = (totalDays) => {
  let daysAgo = totalDays - 1; // offset by one since including today
  return moment().subtract(daysAgo, 'days').startOf('day').format(yearMonthDay);
}

const today = () => moment().format(yearMonthDay);

export default class CallChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        type: "ALL_CALLS",
        view: 'CALLS',
        status: "ALL_CALLS",
        fromDate: setFromDate(defaultDaysAgo),
        toDate: today(),
        dateRangeVisibility: 'hide',
      },
      dateCounts: {},
      apiReturn: false,
      loadTimeExpired: false,
      legendItem: '',
      internalLegend: true,
      inboundLegend: true,
      outLocalLegend: true,
      outLdLegend: true,
      internationalLegend: true,
      otherLegend: true,
    };
    this.setDateRange = this.setDateRange.bind(this);
    this.setCustomDate = this.setCustomDate.bind(this);
    this.setCallFilter = this.setCallFilter.bind(this);
    this.handleSetLegend = this.handleSetLegend.bind(this);
  };

  componentDidMount() {
    this.filterCallActivity();
  }

  filterCallActivity = () => {
    if (this.state.filters.fromDate && this.state.filters.toDate) {
      let apiPathAndParams = 'admin/CallActivity' + this.callActivityFilterString();
      this.fetchCallActivity(apiPathAndParams);
    }
  }

  callActivityFilterString = () => {
    let filters = this.state.filters;
    let filterString = '?CallType=' + filters.type;
    filterString += '&CallView=' + filters.view;
    filterString += '&CallStatus=' + filters.status;
    filterString += '&FromDate=' + moment(filters.fromDate).format(yearMonthDay);
    filterString += '&ToDate=' + moment(filters.toDate).format(yearMonthDay);
    return filterString
  }

  fetchCallActivity = (apiPath, errorMsg) => {
    this.setState({ apiReturn: false, loadTimeExpired: false });
    this.forcedLoadTime();
    fetchData(apiPath).then((data) => {
      if (data !== null) {
        //console.log("This is call activity data",data)
        let count = data.reduce((total, currentValue) => {
          return total + currentValue.ALL_CALLS;
        }, 0);
        //console.log("Count value",count)
        sessionStorage.setItem("callActivityCount", count)
        this.setState({
          callActivity: data,
          dateCounts: {
            internal: this.mapCallActivity(data, 'INTERNAL'),
            inbound: this.mapCallActivity(data, 'INBOUND'),
            outboundLocal: this.mapCallActivity(data, 'OUTBOUND_LOCAL'),
            outboundLd: this.mapCallActivity(data, 'OUTBOUND_LD'),
            international: this.mapCallActivity(data, 'INTERNATIONAL'),
            other: this.mapCallActivity(data, 'OTHER'),
          },
          apiReturn: true
        });
      } else {
        this.setState({
          callActivity: null,
          apiReturn: true
        });
      };
    }).catch(() => {
      this.setState({
        callActivity: null,
        apiReturn: true
      });
    }
    )
  }

  mapCallActivity = (data, propertyName) => {
    let startDate = moment(this.state.filters.fromDate);
    let endDate = moment(this.state.filters.toDate).add(1, 'days');
    let totalDays = endDate.diff(startDate, 'days');
    let dates = [];
    for (var i = 0; i < totalDays; i++) {
      dates.unshift(endDate.subtract(1, 'days').format(yearMonthDay));
    }
    this.setState({ dates: dates });
    return dates.map(date => {
      let dataItems = data.filter(item => item.CallDate === date);
      let dataItem = null;
      if (dataItems.length > 0) dataItem = dataItems[0];
      return dataItem ? dataItem[propertyName] : 0;
    })
  }

  setDateRange = (event) => {
    let filters = { ...this.state.filters };
    if (event.target.value != custom) {
      filters.fromDate = event.target.value;
      filters.toDate = today();
      filters.dateRangeVisibility = 'hide';
      sessionStorage.setItem('CallActivityFilters', JSON.stringify(filters));
      this.redrawChart(filters);
    } else {
      filters.fromDate = event.target.value;
      filters.toDate = today();
      filters.dateRangeVisibility = 'show';
      this.setState({ filters: filters });
    }
  }

  setCallFilter = (event, filterKey) => {
    let filters = { ...this.state.filters };
    filters[filterKey] = event.target.value;
    sessionStorage.setItem('CallActivityFilters', JSON.stringify(filters));
    this.redrawChart(filters);
  }

  setCustomDate = (event, filterKey) => {
    let filters = { ...this.state.filters };
    if (event.sender.value()) {
      var date = moment(event.sender.value()).format("YYYY-MM-DD");
    } else {
      var date = null;
    }
    filters[filterKey] = date;
    sessionStorage.setItem('CallActivityFilters', JSON.stringify(filters));
    if (this.validCustomDate(filters, filterKey, date)) this.redrawChart(filters);
  }

  validCustomDate = (filters, filterKey, date) => {
    let fromDate = moment(filterKey == 'fromDate' ? date : this.state.filters.fromDate);
    let toDate = moment(filterKey == 'toDate' ? date : this.state.filters.toDate);
    if (date === null) {
      this.setState({ filters: filters, errorMsg: 'Both dates must be provided to use a custom date range.' });
    } else if (fromDate.isBefore(toDate) || fromDate._i == toDate._i) {
      this.setState({ filters: filters, errorMsg: null });
      var isValid = true;
    } else {
      this.setState({ filters: filters, errorMsg: 'The start date must be before the end date.' });
      var isValid = false;
    }
    return isValid;
  }

  redrawChart = (filters) => {
    this.setState({ filters: filters }, () => {
      // make API request after state since needed for API query string
      this.filterCallActivity();
    });
  }

  callCounts = (category) => {
    let countsArray = this.state.dateCounts[category];
    if (this.noResults()) {
      return 0;
    } else {
      let count = countsArray.reduce((total, value) => total + value)
      return helper.numberWithDelimiter(count);
    }
  }

  maxCount = (data) => {
    let counts = values(this.state.dateCounts);
    let flatCounts = Array.prototype.concat(...counts);
    return Math.max(...flatCounts) * 1.05 // adding 5% buffer
  }

  chartSeries() {
    const chartSeriesConfig = (name, data, color) => ({ name: name, data: data, color: color });
    let counts = this.state.dateCounts;
    let seriesList = [
      chartSeriesConfig('Internal', counts.internal, internal_color),
      chartSeriesConfig('Inbound', counts.inbound, inbound_color),
      chartSeriesConfig('Out Local', counts.outboundLocal, outlocal_color),
      chartSeriesConfig('Out LD', counts.outboundLd, outLD_color),
      chartSeriesConfig('International', counts.international, international_color),
      chartSeriesConfig('Other', counts.other, other_color),
    ];
    if (this.state.legendItem === 'internal') {
      return seriesList.filter(item => item.name === 'Internal');
    } else if (this.state.legendItem === 'inbound') {
      return seriesList.filter(item => item.name === 'Inbound');
    } else if (this.state.legendItem === 'out_local') {
      return seriesList.filter(item => item.name === 'Out Local');
    } else if (this.state.legendItem === 'out_ld') {
      return seriesList.filter(item => item.name === 'Out LD');
    } else if (this.state.legendItem === 'international') {
      return seriesList.filter(item => item.name === 'International');
    } else if (this.state.legendItem === 'other') {
      return seriesList.filter(item => item.name === 'Other');
    } else {
      return seriesList;
    }
  }

  chartOptions = () => {
    let counts = this.state.dateCounts;
    let dates = this.state.dates;
    if (counts != null && dates != null) {
      return (
        {
          legend: {
            visible: false
          },
          seriesDefaults: {
            type: "line"
          },
          series: this.chartSeries(),
          chartArea: {
            height: 320
          },
          valueAxis: {
            min: 0,
            max: this.maxCount(),
            line: {
              visible: false
            },
            minorGridLines: {
              visible: true
            },
            labels: {
              rotation: "auto",
              color: '#b3b1b1'
            }
          },
          categoryAxis: {
            categories: dates.map(date => moment(date).format(calendarFormat)),
            majorGridLines: {
              visible: false
            },
            color: '#FFF'
          },
          tooltip: {
            visible: true,
            format: "Value: {0:N0}",
            template: "#= series.name # : #= value # on #= kendo.toString(category) # "
          }
        }
      )
    }
  }

  noResults = () => (this.state.callActivity == null || this.state.errorMsg);

  chartContent = () => {
    if (this.state.loadTimeExpired && this.state.apiReturn) {
      if (this.noResults()) {
        return (
          <p className='no-data-returned text-center'>
            {this.state.errorMsg || 'No call activity found with the above filters selected.'}
          </p>
        )
      } else return <Chart {...this.chartOptions()} />
    }
    else return <DataLoadingSpinner className='load spinner call-activity-spinner' />
  }

  forcedLoadTime = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ loadTimeExpired: true })
    }, loadTime);
    return true;
  }

  handleSetLegend = (legendItem) => {
    let legendItems = [{ name: 'internalLegend', legendName: 'internal', value: this.state.internalLegend, selected: legendItem === 'internalLegend' },
    { name: 'inboundLegend', legendName: 'inbound', value: this.state.inboundLegend, selected: legendItem === 'inboundLegend' },
    { name: 'outLocalLegend', legendName: 'out_local', value: this.state.outLocalLegend, selected: legendItem === 'outLocalLegend' },
    { name: 'outLdLegend', legendName: 'out_ld', value: this.state.outLdLegend, selected: legendItem === 'outLdLegend' },
    { name: 'internationalLegend', legendName: 'international', value: this.state.internationalLegend, selected: legendItem === 'internationalLegend' },
    { name: 'otherLegend', legendName: 'other', value: this.state.otherLegend, selected: legendItem === 'otherLegend' }
    ]
    let notSelectedItems = legendItems.filter(resp => resp.selected === false);
    let prop1 = notSelectedItems[0], prop2 = notSelectedItems[1], prop3 = notSelectedItems[2], prop4 = notSelectedItems[3], prop5 = notSelectedItems[4]
    let selectedItem = legendItems.filter(resp => resp.selected === true)[0].legendName
    if (this.state.legendItem === selectedItem)
      selectedItem = '';
    this.setState({
      legendItem: selectedItem,
      [prop1.name]: !prop1.value,
      [prop2.name]: !prop2.value,
      [prop3.name]: !prop3.value,
      [prop4.name]: !prop4.value,
      [prop5.name]: !prop5.value,
    });
  }

  callCategoryHyperlink = (category) => {
    return (
      <a onClick={() => {
        sessionStorage.setItem('EnterpriseCallCategoryFilter', category);
        this.props.transition();
      }}>{category}</a>
    )
  }

  handleSetOutLocalLegend = () => {
    if (this.state.outLocalLegend) {
      this.setState({
        internalLegend: !this.state.internalLegend,
        inboundLegend: !this.state.inboundLegend,
        outLdLegend: !this.state.outLdLegend,
        internationalLegend: !this.state.internationalLegend
      });
      if ((this.state.internalLegend && this.state.inboundLegend && this.state.outLdLegend && this.state.internationalLegend)) this.setState({ legendItem: 'out_local' });
      if (!(this.state.internalLegend && this.state.inboundLegend && this.state.outLdLegend && this.state.internationalLegend)) this.setState({ legendItem: '' });
      return;

    }
  }

  handleSetOutLdLegend = () => {
    if (this.state.outLdLegend) {
      this.setState({
        internalLegend: !this.state.internalLegend,
        inboundLegend: !this.state.inboundLegend,
        outLocalLegend: !this.state.outLocalLegend,
        internationalLegend: !this.state.internationalLegend
      });
      if ((this.state.internalLegend && this.state.inboundLegend && this.state.outLocalLegend && this.state.internationalLegend)) this.setState({ legendItem: 'out_ld' });
      if (!(this.state.internalLegend && this.state.inboundLegend && this.state.outLocalLegend && this.state.internationalLegend)) this.setState({ legendItem: '' });
      return;

    }
  }

  handleSetInternationalLegend = () => {
    if (this.state.internationalLegend) {
      this.setState({
        internalLegend: !this.state.internalLegend,
        inboundLegend: !this.state.inboundLegend,
        outLocalLegend: !this.state.outLocalLegend,
        outLdLegend: !this.state.outLdLegend
      });
      if ((this.state.internalLegend && this.state.inboundLegend && this.state.outLocalLegend && this.state.outLdLegend)) this.setState({ legendItem: 'international' });
      if (!(this.state.internalLegend && this.state.inboundLegend && this.state.outLocalLegend && this.state.outLdLegend)) this.setState({ legendItem: '' });
      return;

    }
  }

  render() {
    if (sessionStorage.IsNetSapiens === "false") {
      if (sessionStorage.enterpriseType === 'VOICE' || sessionStorage.enterpriseType === 'MIXED') {
        return (
          <div className="activity card">
            <div className="card-header">
              <h2 className="move" style={{ display: 'inline-block' }}>Call Activity</h2>
              <a style={{ display: 'inline-block', float: 'right' }} onClick={this.props.transition}>Call Details</a>
            </div>
            <div className="card-content">
              <div className="activity-actions">
                <div className="activity-action">
                  <div className="filter">
                    <select className="form-control"
                      value={this.state.filters.type}
                      onChange={(event) => { this.setCallFilter(event, type) }} >
                      <option value='ALL_CALLS'>All Calls</option>
                      <option value='INCOMING'>Incoming</option>
                      <option value='OUTGOING'>Outgoing</option>
                    </select>
                  </div>
                  <div className="filter">
                    <select className="form-control"
                      onChange={(event) => { this.setCallFilter(event, view) }} >
                      <option value="CALLS">Calls</option>
                      <option value="MINUTES">Minutes</option>
                    </select>
                  </div>
                  <div className="filter">
                    <select className="call-log-dates form-control"
                      value={this.state.filters.status}
                      onChange={(event) => { this.setCallFilter(event, status) }} >
                      <option value="ALL_CALLS">All Calls</option>
                      <option value="CONNECTED">Connected</option>
                      <option value="NO_ANSWER">No Answer</option>
                      <option value="INCOMPLETE">Incomplete</option>
                      <option value="FORWARDED">Forwarded</option>
                    </select>
                  </div>
                  <div className='filter' >
                    <select className="call-log-dates form-control" onChange={this.setDateRange}>
                      <option value={setFromDate(7)}>Last 7 Days</option>
                      <option value={setFromDate(30)}>Last 30 Days</option>
                      <option value={setFromDate(90)}>Last 90 Days</option>
                      <option value='custom'>Custom</option>
                    </select>
                  </div>
                  <div ref='customDates' className={'halves date-range-select ' + this.state.filters.dateRangeVisibility}>
                    <div className="half">
                      <label>Start Date</label>
                      <DatePicker
                        value={moment(this.state.filters.fromDate).format(yearMonthDay)}
                        change={(event) => { this.setCustomDate(event, fromDate) }}
                      />
                    </div>
                    <div className="half">
                      <label>End Date</label>
                      <DatePicker
                        value={moment(this.state.filters.toDate).format(yearMonthDay)}
                        change={(event) => { this.setCustomDate(event, toDate) }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="highlight-numbers">
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.internalLegend === false ? 'rgba(128, 128, 128, 0.34)' : internal_color }} onClick={(evt) => { this.handleSetLegend('internalLegend') }}></div>{this.callCounts('internal')} {this.callCategoryHyperlink('Internal')}
                </div>
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.inboundLegend === false ? 'rgba(128, 128, 128, 0.34)' : inbound_color }} onClick={(evt) => { this.handleSetLegend('inboundLegend') }}></div>{this.callCounts('inbound')} {this.callCategoryHyperlink('Inbound')}
                </div>
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.outLocalLegend === false ? 'rgba(128, 128, 128, 0.34)' : outlocal_color }} onClick={(evt) => { this.handleSetLegend('outLocalLegend') }}></div>{this.callCounts('outboundLocal')} {this.callCategoryHyperlink('Out Local')}
                </div>
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.outLdLegend === false ? 'rgba(128, 128, 128, 0.34)' : outLD_color }} onClick={(evt) => { this.handleSetLegend('outLdLegend') }}></div>{this.callCounts('outboundLd')} {this.callCategoryHyperlink('Out LD')}
                </div>
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.internationalLegend === false ? 'rgba(128, 128, 128, 0.34)' : international_color }} onClick={(evt) => { this.handleSetLegend('internationalLegend') }}></div>{this.callCounts('international')} {this.callCategoryHyperlink('International')}
                </div>
                <div className="highlight-number">
                  <div className="num" style={{ backgroundColor: this.state.otherLegend === false ? 'rgba(128, 128, 128, 0.34)' : other_color }} onClick={(evt) => { this.handleSetLegend('otherLegend') }}></div>{this.callCounts('other')} {this.callCategoryHyperlink('Other')}
                </div>
              </div>
              <div className="k-chart-holder">
                {this.chartContent()}
              </div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }
    else { return null }
  }
};