import React, { useState, useEffect } from "react";
import * as apiCalls from "../../requests/api_calls";
import { Modal } from "react-bootstrap";
import {
  Grid,
  GridColumn as Column,
  GridCell,
} from "@progress/kendo-react-grid";
import "./circuits.css";
import { Button } from "@progress/kendo-react-buttons";
import { Link, withRouter } from "react-router-dom";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from '../../components/spinners';

function CircuitList() {
  const [showModal, setShowModal] = useState(false);
  const [circuitList, setCircuitList] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [filteredCircuitList, setFilteredCircuitList] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [fullListings, setFullListings] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [Sort, setSort] = useState([{ field: "ActiveStatus", dir: "asc" }],)
  const [editCircuitItem, setEditCircuitItem] = useState({
    CircuitId: "",
    CircuitName: "",
    NotificationEmail: "",
    CustomerProductId: "",
    SpeedDownstream: "",
    SpeedUpstream: "",
    TermInMonth: "",
    CheckMkHostName: ""
  });

  useEffect(() => {
    setLoading(true);

    //making api call
    fetchCircuits();
  }, []);

  const fetchCircuits = async () => {
    let apiPath = "admin/GetCircuitsList";

    await apiCalls.fetchData(apiPath).then((data) => {
      setLoading(true);
      if (data) {
        //data.forEach(async (itm) => {
        // if (itm.CheckMkHostName && itm.CheckMkHostName !== "") {
        //   let obj = await fetchActiveHosts(itm.CheckMkHostName);
        //   let tt = JSON.parse(obj).ResultDetail;
        //   let ti = JSON.parse(tt).state_text;
        //   if (ti === "OK") {
        //     itm.isActive = true;
        //   } else {
        //     itm.isActive = false;
        //   }
        // }
        setCircuitList(data);
        setFilteredCircuitList(data);
        setFullListings(JSON.parse(JSON.stringify(data)));
        setLoading(false);
        //});
      } else {
        setLoading(false);
      }
    });
  };

  const fetchActiveHosts = async (hostname) => {
    setStatusLoading(true);
    let apiPath = `admin/CircuitMonitoring/GetCurrentStatusForHost?hostname=${hostname}`;
    return await apiCalls.fetchData(apiPath).then(data => {
      if (data) {
        setStatusLoading(false);
        return data;
      } else {
        setStatusLoading(false);
        return null;
      }
    }).catch(err => console.error(err));
  };

  const handleSortChange = (e) => {
    setFilteredCircuitList(orderBy(filteredCircuitList, Sort));
    setSort(e.sort)
  }

  const searchCircuits = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = fullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'CircuitId', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'CircuitName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Address1', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'City', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'State', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Zip', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      delayedSearch(services);
    }
    else {
      delayedSearch(fullDetails);
    }
  }

  const delayedSearch = async (services) => {
    // await setLoading(true)
    // if (this.timeout) clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //this.setState({ UsersList: services, loading: false });
    setFilteredCircuitList(services);
    //setLoading(false);
    //}, settings.searchDelay);
  }

  const InterfaceCell = (props) => {
    let { CheckMkHostName } = props.dataItem;
    if (CheckMkHostName) {
      return (
        <td>
          {CheckMkHostName}
        </td>
      )
    } else {
      return (
        <td>N/A</td>
      )
    }
  }

  const SpeedCell = (props) => {
    let { SpeedDownstream } = props.dataItem;
    if (SpeedDownstream) {
      return (
        <td>
          {SpeedDownstream}
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  }

  const LocationCell = (props) => {
    let { Address1, City, State, Zip } = props.dataItem;
    if (Address1) {
      return (
        <td>
          {`${Address1}, ${City}, ${State} ${Zip}`}
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  }

  const StatusCell = (props) => {
    let customClassName = "";
    if (props.dataItem.ActiveStatus === "active") {
      customClassName = "dot dot-success";
    }
    if (props.dataItem.ActiveStatus === "inactive") {
      customClassName = "dot dot-error";
    }
    // if (statusLoading) {
    //   return null;
    // } else {
    if (props.dataItem.CheckMkHostName && props.dataItem.CheckMkHostName !== "") {
      return (
        <td>
          <i className={customClassName} />
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
    //}
  };

  const DataLinkCell = (props) => {
    let { CircuitId, CheckMkHostName, CircuitName, DeviceHostName, WANPortAlias, WANPort } = props.dataItem;
    if (CircuitId) {
      if (CheckMkHostName && CheckMkHostName !== "") {
        //console.log("PROPS", props);
        return (
          <td>
            <Link
              to={{
                pathname: `/circuits-analytics/${CircuitId}`,
                state: {
                  CircuitId: CircuitId,
                  HostName: CheckMkHostName,
                  CircuitName: CircuitName,
                  DeviceHostName: DeviceHostName,
                  WANPortAlias: WANPortAlias,
                  WANPort: WANPort,
                }
              }}
              id="editButton"
              className="icon btn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
              </svg>
            </Link>
          </td>
        );
      } else {
        return (
          <td>

          </td>
        );
      }
    } else {
      return <td></td>
    }
  };

  const onHandleShow = (item) => {
    setShowModal(true);
    setEditCircuitItem({
      ...editCircuitItem,
      CircuitId: item.CircuitId,
      NIDMAC: item.NIDMAC,
      NotificationEmail: item.NotificationEmail,
      CircuitName: item.CircuitName,
      LocationName: item.LocationName,
      CustomerProductId: item.CustomerProductId,
      SpeedDownstream: item.SpeedDownstream,
      SpeedUpstream: item.SpeedUpstream,
      TermInMonth: item.TermInMonth,
      CheckMkHostName: item.CheckMkHostName,
    });
  };
  const onHandleClose = () => {
    setShowModal(false);
  };

  const EditCell = (props) => {
    return (
      <>
        {props.dataItem.NotificationEmail != "" && <td>
          <span>
            <a
              id="editButton"
              className="icon btn"
              onClick={() => onHandleShow(props.dataItem)}
            >
              <svg width="14" height="14" viewBox="0 0 24 24">
                <path
                  d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                  transform="scale(0.046875 0.046875)"
                ></path>
              </svg>
            </a>
          </span>
        </td>} </>
    );
  };

  const openANewTicket = (props) => {
    return (
      <>
        {props.dataItem.NotificationEmail != "" && <td>
          <span>
            <Link to={{
              pathname: '/new_ticket',
              state: {
                redirectedFromCircuitsList: [
                  {
                    redirectedFromCircuits: true,
                    CheckMkHostName: props.dataItem.CheckMkHostName,
                    SelectedLocation: props.dataItem.LocationName,
                    LocationAccountNumber: props.dataItem.LocationAccountNumber,
                    CircuitName: props.dataItem.CircuitName,
                    CircuitId: props.dataItem.CircuitId,
                    CustomerProductId: props.dataItem.CustomerProductId,
                    NotificationEmail: props.dataItem.NotificationEmail,
                    SpeedDownstream: props.dataItem.SpeedDownstream,
                    SpeedUpstream: props.dataItem.SpeedUpstream,
                    Status: props.dataItem.Status,
                    NIDIP: props.dataItem.NIDIP,
                    NIDMAC: props.dataItem.NIDMAC,
                    ServiceType: props.dataItem.ServiceType,
                    TermInMonth: props.dataItem.TermInMonth
                  }
                ],

              }
            }}>
              Open Ticket
            </Link>
          </span>
        </td>} </>
    );
  };

  const editCircuitName = (e) => {
    setEditCircuitItem({ ...editCircuitItem, CircuitName: e.target.value });
  };
  const editDownloadSpeed = (e) => {
    //if (typeof parseInt(e.target.value) === "integer" && parseInt(e.target.value) > 0 && parseInt(e.target.value) < 1000000) {
    setEditCircuitItem({ ...editCircuitItem, SpeedDownstream: e.target.value });
    //}
  };
  const editUploadSpeed = (e) => {
    setEditCircuitItem({ ...editCircuitItem, SpeedUpstream: e.target.value });
  };
  const editCircuitNotification = (e) => {
    setEditCircuitItem({
      ...editCircuitItem,
      NotificationEmail: e.target.value,
    });
  };
  const updateCircuitList = async () => {
    //console.log("updated list", editCircuitItem);
    var apiPath = "admin/UpdateCircuitsList";
    let circuitsDTO = {
      CustomerProductId: editCircuitItem.CustomerProductId,
      CircuitId: editCircuitItem.CircuitId,
      CircuitName: editCircuitItem.CircuitName,
      NotificationEmail: editCircuitItem.NotificationEmail,
      NIDMAC: editCircuitItem.NIDMAC,
      LocationName: editCircuitItem.LocationName,
      NIDIP: editCircuitItem.NIDIP,
      SpeedDownstream: editCircuitItem.SpeedDownstream,
      SpeedUpstream: editCircuitItem.SpeedUpstream,
      TermInMonth: editCircuitItem.TermInMonth,
      CheckMkHostName: editCircuitItem.CheckMkHostName,
    };
    // console.log("DATA", circuitsDTO);
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(circuitsDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      fetchCircuits();
      setShowModal(false);
    } else {
      setLoading(false);
    }
  };

  const gridContent = () => {
    if (loading) {
      return <div style={{ position: "relative", textAlign: "center" }}><DataLoadingSpinner className='load spinner' style={{ marginTop: "3%" }} /></div>
    } else {
      return (
        <div className="circuit-card-holder">
          <div className="favorite-services card">
            <div className="card-header">
              <h2 className="move">Circuits List</h2>
              <div className="search">
                <label htmlFor="fav_services_srch_tb" className="hidden">
                  circuits search
                </label>
                <input
                  id="fav_services_srch_tb"
                  type="text"
                  autoComplete={"off"}
                  className="form-control search-control"
                  onChange={searchCircuits}
                />
                <svg width="12" height="12" viewBox="0 0 23 23">
                  <path
                    d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z"
                    transform="scale(0.046875 0.046875)"
                  ></path>
                </svg>
              </div>

              <div className="card-content">
                <Grid
                  scrollable="none"
                  style={{ height: "auto" }}
                  data={orderBy(filteredCircuitList, Sort)} //{this.state.filteredCircuitList }
                  sortable={{ allowUnsort: true, mode: 'single' }}
                  sort={Sort}
                  onSortChange={handleSortChange}
                >
                  <Column
                    field="CircuitId"
                    title="Circuit ID"
                  //title={headerToolTip("Circuit ID")}
                  //cell={DataLinkCell}
                  />
                  <Column
                    field="CircuitName"
                    title="Circuit Name" //title={headerToolTip("Circuit Name")}
                  />
                  {/* <Column field="NIDMAC" title="NID MAC" /> */}
                  <Column field="SpeedDownstream" title="Speed (Mbps)" cell={SpeedCell} />
                  {/* <Column field="SpeedUpstream" title="Upload Speed (Mbps)" /> */}
                  <Column field="LocationName" title="Location" cell={LocationCell} />
                  {/* <>REPLACE ADDRESS WITH FORMATTED VERSION</> */}
                  {/* <Column field="TermInMonth" title="Contract Term" /> */}
                  {/* <Column
                    field="CheckMkHostName"
                    title="Interface"
                    cell={InterfaceCell} 
                  /> */}
                  <Column
                    field="ActiveStatus"
                    title="Status"
                    cell={StatusCell}
                  />
                  {/* Hiding OpenTicket if hasTicketing is None */}
                  {sessionStorage.hasTicketing === 'NONE' ? null :
                  <Column
                    field="OpenANewTicket"
                    title=" "
                    width="30px"
                    cell={openANewTicket}
                    onRowClick
                  />}
                  <Column
                    field="graph"
                    title=" "
                    width="30px"
                    cell={DataLinkCell}
                  />
                  <Column
                    field="FavoriteBtn"
                    title=" "
                    width="30px"
                    cell={EditCell}
                    onRowClick
                  />
                </Grid>
              </div>
            </div>
          </div>

          {/* <div className="favorite-services card">
            <div className="quick-view">
              <div className="card-header">
                <h2 className="move">Summary Widget</h2>
              </div>
              <div className="card-content">
                <div className="quick-items">
                  <div className="quick-item">
                    <label>Monitored Circuits Up</label>
                    <h3>{upCircuits}</h3>
                  </div>
                  <div className="quick-item">
                    <label>Monitored Circuits Down</label>
                    <h3>{downCircuits}</h3>
                  </div>
                  <div className="quick-item">
                    <label>Total Circuits</label>
                    <h3>{circuitList.length}</h3>
                  </div>
                  <div className="quick-item">
                    <label>Alarms</label>
                    <h3 style={{ textDecorationLine: "underline" }}>0</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <Modal show={showModal} onHide={onHandleClose}>
              <Modal.Header closeButton></Modal.Header>
              <h4 className="modalCircuitsTitle">Edit Circuit Item</h4>
              <Modal.Body>
                <div className="fqdn-setup-modal">
                  <label>Circuit ID</label>
                  <div className="form-group">
                    <input
                      id="new-circuitId"
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={editCircuitItem.CircuitId}
                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>
                  <label>Circuit Name</label>
                  <div className="form-group">
                    <input
                      id="new-circuitName"
                      type="text"
                      className="form-control"
                      value={editCircuitItem.CircuitName}
                      onChange={editCircuitName}
                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>
                  <label>NID MAC</label>
                  <div className="form-group">
                    <input
                      id="new-NIDMAC"
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={editCircuitItem.NIDMAC}
                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>

                  <label>Download Speed (Mbps)</label>
                  <div className="form-group">
                    <input
                      id="new-circuitSpeed"
                      type="text"
                      className="form-control"
                      value={editCircuitItem.SpeedDownstream}
                      onChange={editDownloadSpeed}

                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>

                  <label>Upload Speed (Mbps)</label>
                  <div className="form-group">
                    <input
                      id="new-circuitSpeed"
                      type="text"
                      className="form-control"
                      value={editCircuitItem.SpeedUpstream}
                      onChange={editUploadSpeed}

                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>

                  <label>Location</label>
                  <div className="form-group">
                    <input
                      id="new-location"
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={editCircuitItem.LocationName}

                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>
                  <label>Interface</label>
                  <div className="form-group">
                    <input
                      id="new-interface"
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={editCircuitItem.CheckMkHostName}

                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>
                  <label>Notification Email</label>
                  <div className="form-group">
                    <input
                      id="new-email"
                      type="text"
                      className="form-control"
                      value={editCircuitItem.NotificationEmail}
                      onChange={editCircuitNotification}

                    // onKeyPress={this.saveOnEnter}
                    />
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer> */}
              <hr />
              <div className="btns">
                <Button className="btn" onClick={updateCircuitList}>
                  Save
                </Button>
                <Button className="btn" onClick={() => onHandleClose()}>
                  Cancel
                </Button>
              </div>
              {/* </Modal.Footer> */}
            </Modal>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      {gridContent()}
    </>
  );
}

export default CircuitList;