import React, { Component } from "react";
import { fetchData } from "./../requests/api_calls";

import FQDNSetupModal from "./locations/modals/fQDNSetupModal";
import AutoAttendantRepoManager from "./locations/modals/autoAttendantRepoManager";

import DepartmentModal from "./modals/enterprise_settings/departmentModal";
import EnterpriseDirectoryModal from "./modals/enterprise_settings/enterpriseDirectoryModal";
import EmergencyCallNotificationModal from "./modals/enterprise_settings/emergencyCallNotificationModal";

import AgentDefaultSettingModal from "./modals/enterprise_settings/agentDefaultSettingModal";
import AgentUnavailableCodesModal from "./modals/enterprise_settings/agentUnavailableCodesModal/index.js";
import CallDispositionCodesModal from "./modals/enterprise_settings/callDispositionCodesModal/index.js";
import CenterRoutingPoliciesModal from "./modals/enterprise_settings/centerRoutingPoliciesModal";
import ScheduleModal from "./modals/enterprise_settings/scheduleModal/index.js";
import { connect } from "react-redux";
import { DataLoadingSpinner } from "../components/spinners";

class EnterpriseSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasContactCenter: false,
      loading: false,
      isEnterpriseSectionCollapsed: true,
      isContactCenterSectionCollapsed: true,
    };
  }

  componentDidMount() {
    this.checkIfHasContactCenter();
  }

  checkIfHasContactCenter = async () => {
    this.setState({ loading: true });
    const response = await fetchData("admin/HasContactCenter").then((data) => data);
    this.setState({ hasContactCenter: response.HasContactCenter, loading: false });
  };

  toggleCollapseEnterpriseSection = () => {
    this.setState({ isEnterpriseSectionCollapsed: !this.state.isEnterpriseSectionCollapsed });
  };

  toggleCollapseContactCenterSection = () => {
    this.setState({
      isContactCenterSectionCollapsed: !this.state.isContactCenterSectionCollapsed,
    });
  };

  getEnterpriseSection = () => {
    return (
      <div className="settings-row">
        <div
          className="panel-group settings"
          id="accordionES"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel panel-default">
            {/* Section heading: Enterprise Settings */}
            <div className="panel-heading" role="tab" id="headingES">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordionES"
                href="#collapseEnterpriseServices"
                aria-expanded={!this.state.isEnterpriseSectionCollapsed}
                onClick={this.toggleCollapseEnterpriseSection}
                aria-controls="collapseEnterpriseServices"
              >
                <p
                  className="title"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontFamily: "arial, sans-serif",
                    color: "#555",
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "0",
                  }}
                >
                  Enterprise Services
                  <span style={{ float: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 13"
                      width="18"
                      height="18"
                      fill="rgb(101, 101, 101)"
                      stroke="rgb(101, 101, 101)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: this.state.isEnterpriseSectionCollapsed
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                    >
                      <path
                        fill="rgb(101, 101, 101)"
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </span>
                </p>
              </a>
            </div>
            <div
              id="collapseEnterpriseServices"
              className={`panel-collapse collapse`}
              role="tabpanel"
              aria-labelledby="headingES"
            >
              <div className="panel-body enterprise-services">
                <div className="settings-row">
                  {sessionStorage.IsNetSapiens === 'true' ? null : <DepartmentModal />}
                  <EmergencyCallNotificationModal />
                  {sessionStorage.IsNetSapiens === 'true' ? null : <EnterpriseDirectoryModal />}
                  {sessionStorage.IsNetSapiens === 'true' ? null : <ScheduleModal />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getContactCenterSection = () => {
    return (
      <div className="settings-row">
        <div
          className="panel-group settings"
          id="accordionCC"
          role="tablist"
          aria-multiselectable="true"
        >
          <div className="panel panel-default">
            {/* Section heading: Contact Center */}
            <div className="panel-heading" role="tab" id="headingCC">
              <a
                role="button"
                data-toggle="collapse"
                data-parent="#accordionCC"
                href="#collapseContactCenter"
                aria-expanded={!this.state.isContactCenterSectionCollapsed}
                onClick={this.toggleCollapseContactCenterSection}
                aria-controls="collapseContactCenter"
              >
                <p
                  className="title"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontFamily: "arial, sans-serif",
                    color: "#555",
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "0",
                  }}
                >
                  Contact Center
                  <span style={{ float: "right" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 13"
                      width="18"
                      height="18"
                      fill="rgb(101, 101, 101)"
                      stroke="rgb(101, 101, 101)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: this.state.isContactCenterSectionCollapsed
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                    >
                      <path
                        fill="rgb(101, 101, 101)"
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                      />
                    </svg>
                  </span>
                </p>
              </a>
            </div>

            <div
              id="collapseContactCenter"
              className="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingCC"
            >
              <div className="panel-body enterprise-services">
                <div className="settings-row">
                  <AgentDefaultSettingModal />
                  <AgentUnavailableCodesModal />
                  <CallDispositionCodesModal />
                  <CenterRoutingPoliciesModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="full-card container">
        <div className="iframe-holder card">
          <div className="card-header">
            <h1>Enterprise Settings</h1>
          </div>
          {sessionStorage.IsNetSapiens === 'true' ? null :
            <div>
              <div className="settings-row">
                <FQDNSetupModal />
              </div>
              {/* <div className="settings-row">
            <AutoAttendantRepoManager />
          </div> */}
            </div>
          }

          {this.getEnterpriseSection()}
          {this.state.loading && (
            <div className="content">
              <DataLoadingSpinner
                className="spinner"
                style={{ margin: "10px auto 0", height: "40px" }}
              />
            </div>
          )}
          {this.state.hasContactCenter && this.getContactCenterSection()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AccountNumber: state.reducer.AccountNumber,
  };
};

export default connect(mapStateToProps)(EnterpriseSettings);
// export default EnterpriseSettings;