import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Row, Grid, Col, Button, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchData } from "./../../requests/api_calls";
import { DataLoadingSpinner } from "./../../components/spinners";

class NetSapiens extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showConfirmationModal: false,
      loading: true
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
  }

  handleShow(e) {
    console.log("show moddal")
    this.setState({ showModal: true });
    this.getUserForm();
}

handleClose() {
  this.setState({ showModal: false });
}

  getModalBodyContent = () => {
    return this.getUserForm();
};

getUserForm = () => {
  return (
    <span className="macd-page">
          <button className='btn' onClick={this.handleShow}>NetSapiens Portal</button>

          <Modal
            show={this.state.showModal} 
            dialogClassName="modal-class"
            onHide={this.handleClose}
            bsSize="large"
          >
            <Modal.Header closeButton closeLabel="test test test test est test ets estse">
              <Modal.Title>NetSapiens Portal</Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
              <div>
                <iframe  className="macd-page" id="netSapiensFrame" src=" https://portal.g12com.com/"></iframe>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <hr />
            </Modal.Footer>
          </Modal>
        </span>
  );
};


  render() {
    return (
        <div className="quick-view card">
          <div className="card-header">
            <h2 className="move">NetSapiens</h2>
          </div>
          <div className="card-content">
            {this.getUserForm()}
          </div>
        </div>
      );
  }
}

export default NetSapiens;




